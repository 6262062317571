/**
 * Document : fp-onboarding - info-button-small.css
 * Created on : 14.5.2022, 19:14
 * @author :  Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 14.5.2022: JOml - vytvoření souboru
 **/

/**
 * Document : fp-onboarding - info-button-big.css
 * Created on : 9.2.2022, 17:49
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.info-button-main-small {
    width: 180px;
    height: auto;
    color: dimgrey;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.info-button-info-small {
    width: auto;
    height: auto;
    font-size: 18px;
    align-self: center;
    font-style: normal;
}

.info-button-layout-small {
    width: 90%;
    min-height: 35px;
    margin-top: 5px;
    text-align: center;
    border: 3px solid transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white),
    linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-button-castka-small {
    color: dimgrey;
    font-size: 20px;
    align-self: center;
    font-style: normal;
}

.info-button-castka-small-puvodni {
    color: dimgrey;
    align-self: end;
    margin: 0 5px 0 0;
    font-size: 15px;
    text-decoration: line-through;
    text-decoration-color: rgba(255, 79, 144, 0.6);
    -webkit-text-decoration-line: line-through;
    -webkit-text-decoration-color: rgba(255, 79, 144, 0.6);
    text-decoration-thickness: 2px;
}

@media (max-width: 767px) {
    .info-button-main-small {
        margin-bottom: 15px;
    }

    .info-button-castka-small {
        font-size: 20px;
    }
}

@media (max-width: 479px) {

    .info-button-castka-small {
        font-size: 20px;
    }

    .info-button-info-small {
        font-size: 16px;
    }
}