/**
 * Document : fp-onboarding - info-button-big.css
 * Created on : 9.2.2022, 17:49
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/


.info-button-layout-big {
    width: 180px;
    height: auto;
    border-radius: 10px;
    border: 3px solid #FF4F90;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
}

.info-button-info-big {
    width: auto;
    height: auto;
    font-size: 18px;
    align-self: center;
    font-style: normal;
}

.info-button-castka-big {
    color: rgba(67, 52, 74, 1);
    width: auto;
    height: auto;
    font-size: 26px;
    align-self: center;
    font-style: normal;
}

.info-button-castka-big-puvodni {
    color: white;
    align-self: end;
    margin: 0 0 -10px 0;
    font-size: 15px;
    text-decoration: line-through;
    text-decoration-color: rgba(128, 128, 128, 0.8);
    -webkit-text-decoration-line: line-through;
    -webkit-text-decoration-color: rgba(128, 128, 128, 0.8);
    text-decoration-thickness: 2px;
}

@media (max-width: 767px) {
    .info-button-layout-big {
        margin-bottom: 15px;
    }

    .info-button-main-big {
        height: 90px;
        width: 150px;
    }

    .info-button-castka-big {
        font-size: 20px;
    }
}

@media (max-width: 479px) {

    .info-button-castka-big {
        font-size: 20px;
    }

    .info-button-info-big {
        font-size: 16px;
    }
}

/*.info-button-info,*/
/*.info-button-layout,*/
/*.info-button-main {*/
/*    border-style: dotted;*/
/*    border-color: aqua;*/
/*}*/