/**
 * Document : fp-onboarding - button-potvrzeni.css
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.toggle-button-container {
    height: 45px;
    min-height: 45px;
    border-radius: 10px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border: 3px solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: white;
    margin-bottom: 20px;
}

.toggle-button-container-true {
    background-image: linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%),
    linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%);
}

.toggle-button-container-false {
    background-image: linear-gradient(white, white),
    linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%);
}

.toggle-button-text {
    width: auto;
    height: auto;
    font-size: 24px;
    align-self: center;
}

.toggle-button-text-true {
    color: white;
}

.toggle-button-text-false {
    background: linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.toggle-button-container:hover {
    /*filter: brightness(90%);*/
}

.toggle-button-text:hover,
.toggle-button-container:hover {
    cursor: pointer;
}

@media (max-width: 479px) {
    .toggle-button-text {
        font-size: 18px;
    }
}