.lead-text {
	margin-top: 20px;
}

.lead-form {
	margin-top: 40px;
	margin-bottom: 40px;
}

.lead-form-field {
	width: 275px;
}

.lead-form-field-wrapper {
	margin-right: 40px;
}

.lead-form-error {
	margin-bottom: 40px;
}

.lead-success {
	margin-top: 20px;
}
