/**
 * Document : fp-onboarding - castka-component.css
 * Created on : 9.2.2022, 17:48
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.button-financovani-container {
    width: 320px;
    height: auto;
    display: flex;
    align-self: center;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 1;
    border-radius: 15px;
    flex-direction: row;
    justify-content: space-between;
    /*background-color: #FCAE00;*/
}

.button-financovani-plus,
.button-financovani-minus {
    fill: #FF4F90;
    width: 50px;
    height: 50px;
    transition: 0.3s;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.button-financovani-plus {
    margin-right: 5%;
}

.button-financovani-minus {
    margin-left: 5%;
}

.button-financovani-plus:hover,
.button-financovani-minus:hover {
    /*filter: brightness(85%);*/
    cursor: pointer;
}