/**
 * Document : fp-onboarding - entry-page.css
 * Created on : 6.3.2022, 12:31
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 6.3.2022: JOml - vytvoření souboru
 **/

.entry-page-H1 {
    width: 600px;
    height: auto;
    color: rgba(255, 255, 255, 1);
    align-self: auto;
    font-style: normal;
    text-align: center;
    margin-top: 270px;
}

@media (max-width: 991px) {
    .entry-page-H1 {
        font-size: 1.5rem;
        margin-top: 200px;
        width: 600px;
    }
}

@media (max-width: 767px) {
    .entry-page-H1 {
        width: 400px;
    }
}

@media (max-width: 479px) {
    .entry-page-H1 {
        font-size: 1.2rem;
        margin-top: 130px;
        width: 310px;
    }
}