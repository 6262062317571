/**
 * Document : fp-onboarding - button-potvrzeni.css
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.button-potvrzeni-container {
    width: 280px;
    height: 50px;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 15px;
    flex-direction: row;
    justify-content: center;
    background-color: #FF4F90;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.button-potvrzeni-text {
    color: rgba(255, 255, 255, 1);
    width: auto;
    height: auto;
    font-size: 24px;
    align-self: center;
}

.button-potvrzeni-container:hover {
    /*filter: brightness(85%);*/
}

.button-potvrzeni-text:hover,
.button-potvrzeni-container:hover {
    cursor: pointer;
}

@media (max-width: 479px) {
    .button-potvrzeni-text {
        font-size: 18px;
    }
}