/**
 * Document : fp-onboarding - splatka-item.css
 * Created on : 9.2.2022, 17:49
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.financovani-item-container {
    width: 360px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
}

.financovani-item-row {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.financovani-item-poradiSplatky,
.financovani-item-datum,
.financovani-item-castka {
    width: auto;
    text-align: right;
    color: dimgrey;
    height: auto;
    font-size: 18px;
    font-style: normal;
}

.financovani-item-poradiSplatky{
    width: auto;
    text-align: left;
    margin-left: 0;
}

.financovani-item-datum{
    width: 100px;
    text-align: left;
}

.financovani-item-castka {
    text-align: right;
    background: linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.financovani-item-podtrzitko {
    flex-grow: 2;
    background: linear-gradient(90deg, #48ABDA 0%, #9C6FF3 100%);
    height: 1px;
    margin-left: 10px;
    margin-right: 10px;
}

@media (max-width: 479px) {
    .financovani-item-container {
        width: 300px;
    }

    .financovani-item-poradiSplatky,
    .financovani-item-datum,
    .financovani-item-castka {
        font-size: 14px;
    }

    .financovani-item-datum{
        width: 80px;
    }
}
