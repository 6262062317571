/**
 * Document : fp-onboarding - kalk-page.css
 * Created on : 9.2.2022, 17:51
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.kalkulacka-page-main-div {
    width: 100%;
    height: 125%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    /*background-color: rgba(67, 52, 74, 1);*/
    background-color: white;
}

.kalkulacka-page-main-nadpis-wrapper-div{
    width: 100%;
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.kalkulacka-page-main-nadpis-div {
    width: 500px;
    height: auto;
    /*color: rgba(255, 255, 255, 1);*/
    color: dimgrey;
    background-color: white;
    align-self: auto;
    font-style: normal;
    padding-top: 10px;
    margin-bottom: 0;
    border-radius: 20px 20px 0 0;
}

.kalkulacka-page-vysefinancovani {
    width: auto;
    height: auto;
    /*color: rgba(125, 98, 137, 1);*/
    /*color: white;*/
    color: dimgrey;
    align-self: auto;
    font-style: normal;
}

.kalkulacka-page-button-financovani {
    width: auto;
    height: auto;
    align-self: auto;
}

.kalkulacka-page-financ-slider {
    width: 700px;
    margin-bottom: 20px;
}

.kalkulacka-page-odloz-slider {
    width: 500px;
    margin-bottom: 30px;
}

.kalkulacka-page-postpone-toggle {
    width: 400px;
}

.kalkulacka-page-nabizenasplatnost {
    width: auto;
    height: auto;
    /*color: rgba(125, 98, 137, 1);*/
    align-self: auto;
    font-style: normal;
    margin-bottom: 20px;
}

.kalkulacka-page-list-produkty {
    width: 600px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.kalkulacka-page-prefer-financ {
    width: auto;
    height: auto;
    /*color: rgba(125, 98, 137, 1);*/
    color: dimgrey;
    align-self: auto;
    font-style: normal;
    margin-bottom: 10px;
}

.kalkulacka-page-vbox-bottom {
    width: 600px;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
}

.kalkulacka-page-vbox-bottom-crowdfunding {
    border: 5px solid #00DEC4;
    border-radius: 30px;
    padding: 20px;
}

.kalkulacka-page-splatky-box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
}

.kalkulacka-page-prehled-splatek {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
}

.kalkulacka-page-souhrn {
    width: auto;
    height: auto;
    /*color: #ffffff;*/
    color: dimgrey;
    /*font-size: 30px;*/
    align-self: auto;
    font-style: normal;
    text-align: left;
}

.kalkulacka-page-list-souhrn {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.kalkulacka-page-list-splatky-crowdfunding {
    /*background: white;*/
    margin-left: -45%;
    margin-right: 20px;
    font-size: 30px;
    background-color: white;
}

.kalkulacka-page-list-splatky-crowdfunding-hbox {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.kalkulacka-page-list-splatky-crowdfunding-robot-wrapper {
    position: absolute;
    margin-left: 350px;
    margin-top: 40px;
    display: flex;
    align-items: start;
    flex-direction: row;
    /*justify-content: space-between;*/
}

.kalkulacka-page-list-splatky-crowdfunding-bubble {
    color: white;
    margin-left: 20px;
    background-color: #00DEC4;
    font-size: 18px;
    padding: 10px;
    border-radius: 0 20px 20px 20px;
}

.kalkulacka-page-list-splatky-crowdfunding-text {
    color: #07D0B9;
}

.kalkulacka-page-list-splatky-crowdfunding-bottom-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.kalkulacka-page-list-splatky-crowdfunding-bottom-usetrite{
    text-align: center;
    margin: 0 10px 30px 10px;
}

.kalkulacka-page-list-splatky-crowdfunding-bottom-usetrite-top{
    font-size: 18px;
}

.kalkulacka-page-list-splatky-crowdfunding-bottom-usetrite-bottom{
    color: #00DEC4;
    font-size: 22px;
}

.kalkulacka-page-list-splatky-crowdfunding-bottom-box {
    background-color: #07D0B9;
    padding: 10px;
    text-align: left;
    border-radius: 10px;
    margin-bottom: 15px;
}

.kalkulacka-page-list-splatky-crowdfunding-bottom-box-text {
    color: white;
}

.kalkulacka-page-list-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.kalkulacka-page-list-bottom-crowdfunding {
    width: 100%;
    /*margin-top: 10px;*/
    margin-bottom: 20px;
    text-align: center;
}

.kalkulacka-page-list-bottom-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .kalkulacka-page-list-produkty {
        width: 600px;
        align-items: center;
        justify-content: space-evenly;
    }

    .kalkulacka-page-financ-slider {
        width: 500px;
    }

    .kalkulacka-page-odloz-slider {
        width: 350px;
    }

    .kalkulacka-page-main-nadpis-div {
        font-size: 2rem;
    }

    .kalkulacka-page-list-splatky-crowdfunding {
        /*background: white;*/
        margin-left: 0;
        font-size: 30px;
        background-color: white;
    }

    .kalkulacka-page-list-splatky-crowdfunding-robot-wrapper {
        margin-left: 305px;
    }

    .kalkulacka-page-list-splatky-crowdfunding-bottom-box {
        margin-right: 10px;
    }
}

@media (max-width: 767px) {

    .kalkulacka-page-main-nadpis-div {
        font-size: 1.5rem;
        margin-top: 70px;
        width: 400px;
    }

    .kalkulacka-page-list-produkty {
        width: 330px;
        min-height: 130px;
        justify-content: space-evenly;
    }

    .kalkulacka-page-list-info {
        width: 440px;
    }

    .kalkulacka-page-financ-slider
    {
        width: 350px;
    }

    .kalkulacka-page-odloz-slider {
        width: 250px;
    }

    .kalkulacka-page-vbox-bottom {
        width: 400px;
        align-items: center;
        justify-content: center;
    }

    .kalkulacka-page-splatky-box {
        flex-direction: column;
        align-items: center;
    }

    .kalkulacka-page-prehled-splatek {
        align-items: center;
    }

    .kalkulacka-page-list-bottom-buttons {
        width: 100%;
    }

    .kalkulacka-page-list-splatky-crowdfunding {
        font-size: 28px;
    }

    .kalkulacka-page-list-splatky-crowdfunding-robot-wrapper {
        background-color: rgba(242, 249, 248, 0.85);
        padding: 10px;
        border-radius: 10px;
        /*margin-left: 50px;*/
        /*width: auto;*/
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
    }

    .kalkulacka-page-postpone-toggle {
        width: 380px;
    }

    .kalkulacka-page-list-splatky-crowdfunding-bottom-box {
        margin-right: 0;
    }
}

@media (max-width: 479px) {

    .kalkulacka-page-main-nadpis-div {
        font-size: 1.5rem;
        width: 300px;
    }

    .kalkulacka-page-souhrn,
    .kalkulacka-page-nabizenasplatnost,
    .kalkulacka-page-prefer-financ,
    .kalkulacka-page-vysefinancovani {
        font-size: 1rem;
    }

    .kalkulacka-page-prefer-financ,
    .kalkulacka-page-nabizenasplatnost{
        margin-bottom: 10px;
    }

    .kalkulacka-page-list-produkty {
        width: 290px;
    }

    .kalkulacka-page-list-info {
        width: 300px;
    }

    .kalkulacka-page-financ-slider
    {
        width: 200px;
    }

    .kalkulacka-page-odloz-slider {
        width: 180px;
    }

    .kalkulacka-page-vbox-bottom {
        width: 320px;
        align-items: center;
        justify-content: center;
    }

    .kalkulacka-page-list-info {
        width: 250px;
        flex-direction: column;
    }

    .kalkulacka-page-list-splatky-crowdfunding {
        font-size: 20px;
    }

    .kalkulacka-page-postpone-toggle {
        width: 270px;
    }

    .kalkulacka-page-list-bottom-crowdfunding,
    .kalkulacka-page-list-splatky-crowdfunding-bottom-usetrite-top{
        font-size: 14px;
    }

    .kalkulacka-page-list-splatky-crowdfunding-bottom-usetrite-bottom{
        font-size: 18px;
    }
}

/* Zobrazení divů*/
/*.kalkulacka-page-odloz-slider,*/
/*.kalkulacka-page-financ-slider,*/
/*.kalkulacka-page-vysefinancovani,*/
/*.kalkulacka-page-nabizenasplatnost,*/
/*.kalkulacka-page-list-produkty,*/
/*.kalkulacka-page-prefer-financ,*/
/*.kalkulacka-page-souhrn,*/
/*.kalkulacka-page-list-souhrn,*/
/*.kalkulacka-page-list-info,*/
/*.flowPay-page-image {*/
/*    border-style: dotted;*/
/*    color: black;*/
/*}*/