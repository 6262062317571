* {
	margin: 0;
	padding: 0;
}

html,
body {
	width: 100%;
	height: 100%;
	/* background-color: #43344A; */
	background-color: white;
	/* color: white; */
	/*color: dimgrey;*/
	/* color: rgb(105, 105, 105); */
	color: #43344A;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
 width: 100%;
 height: 100%;
}

.content {
	width: 100%;
	height: 100%;

	/* color: rgba(255, 255, 255, 0.7); */
	/* color: rgb(105, 105, 105); */
	color: #43344A;

	font-family: "Nunito", sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;

	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;

	flex: 1 1 auto; 
	position: relative;
  min-width: 1px;
  max-width: 720px;
  margin: 0 auto;
  z-index: 1;
}

.stepper {
	width: 100%;

	/* color: rgba(255, 255, 255, 0.7); */
	font-family: "Nunito", sans-serif;
	/* color: white; */
	/* color: rgb(105, 105, 105); */
	color: #43344A;
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;

	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;

	flex: 1 1 auto; 
	position: relative;
  min-width: 1px;
  max-width: 720px;
  margin: 20px auto;
  z-index: 1;
}

h1 {
	font-size: 36px;
	line-height: 44px;
	/* color: white; */
	/* color: rgb(105, 105, 105); */
	color: #43344A;
	margin-top: 12px;
	margin-bottom: 12px;
}

.loading {
	width: 100%;
	height: calc(100% - 110px);
	align-items: center;
	justify-content: center;
}

.noOffer {
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	/* color: rgba(255, 255, 255, 1); */
	/* color: rgb(105, 105, 105); */
	color: #43344A;
	font-size: 2rem;
}

.notSupported {
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	/* color: rgba(255, 255, 255, 1); */
	/* color: rgb(105, 105, 105); */
	color: #43344A;
	font-size: 1rem;
}

.nunito600 {
	text-align: center;
	font-family: 'Nunito', sans-serif;
	font-weight: 600;
	line-height: normal;
	font-stretch: normal;
}

.nunito700 {
	text-align: center;
	font-family: 'Nunito', sans-serif;
	font-weight: 700;
	line-height: normal;
	font-stretch: normal;
}

.nunito800 {
	text-align: center;
	font-family: 'Nunito', sans-serif;
	font-weight: 800;
	line-height: normal;
	font-stretch: normal;
}

.inter400 {
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	line-height: normal;
	font-stretch: normal;
}

.inter500 {
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	line-height: normal;
	font-stretch: normal;
}

.inter600 {
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	line-height: normal;
	font-stretch: normal;
}

.inter700 {
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	line-height: normal;
	font-stretch: normal;
}

.inter800 {
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-weight: 800;
	line-height: normal;
	font-stretch: normal;
}

.page-main-div {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	background-color: rgba(67, 52, 74, 1);
}

.flowPay-page-image {
	top: 20px;
	left: 70px;
	position: absolute;
	object-fit: cover;
	height: 30px;
}

.page-main-nadpis-div {
	width: auto;
	height: auto;
	/* color: rgba(255, 255, 255, 1); */
	/* color: rgb(105, 105, 105); */
	color: #43344A;
	align-self: auto;
	font-style: normal;
	margin-top: 130px;
}

.button-potvrzeni-div {
	margin-top: 30px;
	margin-bottom: 50px;
}

@media (max-width: 991px) {
	.page-main-nadpis-div {
		font-size: 2rem;
	}
}

@media (max-width: 767px) {
	.flowPay-page-image {
		left: 0;
		right: 0;
		margin: auto;
	}

	.page-main-nadpis-div {
		font-size: 1.5rem;
	}
}

@media (max-width: 479px) {
	.flowPay-page-image {
		height: 30px;
	}
}