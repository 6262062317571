/**
 * Document : fp-onboarding - consent-page.css
 * Created on : 5.3.2022, 14:19
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 5.3.2022: JOml - vytvoření souboru
 **/

.consent-page-H1 {
    width: 900px;
    height: auto;
    color: rgba(255, 255, 255, 1);
    align-self: auto;
    font-style: normal;
    font-size: 30px;
    text-align: center;
    margin-top: 270px;
}

.consent-page-H2 {
    width: 600px;
    height: auto;
    color: rgba(255, 255, 255, 1);
    align-self: auto;
    font-style: normal;
    text-align: center;
    /*margin-top: 300px;*/
}

@media (max-width: 991px) {
    .consent-page-H1 {
        font-size: 1.5rem;
        margin-top: 200px;
        width: 600px;
    }

    .consent-page-H2 {
        font-size: 1.3rem;
        width: 450px;
    }
}

@media (max-width: 767px) {
    .consent-page-H1 {
        width: 400px;
    }

    .consent-page-H2 {
        width: 350px;
    }
}

@media (max-width: 479px) {
    .consent-page-H1 {
        width: 310px;
        margin-top: 130px;
    }

    .consent-page-H2 {
        width: 300px;
        font-size: 1rem;
        margin-top: 20px;
    }
}