/**
 * Document : fp-onboarding - product-button.css
 * Created on : 9.2.2022, 17:48
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.button-product-main {
    position: relative;
    align-items: center;
    justify-content: center;
}

.button-product-container {
    width: 130px;
    height: 50px;
    display: flex;
    transition: 0.3s;
    border-radius: 12px;
}

.button-product-text {
    width: 100%;
    height: auto;
    font-size: 18px;
    align-self: center;
    font-style: normal;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.button-product-container:hover {
    /*filter: brightness(85%);*/
}

.button-product-text:hover,
.button-product-container:hover,
.button-product-main:hover {
    cursor: pointer;
}

.button-product-default {
    /*background-color: #7D6289;*/
    border: 3px solid darkgrey;
}

.button-product-active {
    /*background-color: #00DEC4;*/
    border: 3px solid #FF4F90;
}

.button-product-selected {
    background-color: #FF4F90;
    border: 3px solid #FF4F90;
}

@media (prefers-color-scheme: dark) {
    .button-product-text {
        color: white;
    }
}