.app,
.form {
 width: 100%;
 height: calc(100% - 110px);
}

.fullsize {
	width: 100%;
	height: calc(100% - 110px);
  text-align: center;
	vertical-align: middle;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	position: relative;
	/*align-items: left;*/
	align-items: flex-start;
	justify-content: baseline;
}

.logoImg {
	width: 263px;
	height: 46px;
	margin: 25px 80px 0;
}

.logoRight {
	width: 328.8px;
	height: 46px;
	margin: 25px 80px 0;
	margin-left: auto;
}

@media (max-width: 600px) {
	.header {
		align-items: center;
		justify-content: flex-start;
	}
	.logoImg {
		height: 30px;
	}	
	.stepper {
		align-items: center;
	}
}

a {
	display: inline;
}
