.connections {
	width: 100%;
	height: 100%;

	color: #43344A;

	font-family: "Nunito", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	text-align: left;

	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: top;

	flex: 1 1 auto; 
	position: relative;
  min-width: 1px;
  max-width: 720px;
  margin: 50px auto;
  z-index: 1;
}

.hotglue {
	margin-top: 50px;
}

.form-field {
	margin-top: 40px;
}

.form-consent {
	margin-top: 40px;
}

.form-button {
	margin-top: 40px;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 40px;
}

.connection-text {
	margin-bottom: 40px;
}

.hg-flow-title {
	display: none;
}

.hg-flow-subtitle  {
	display: none;
}
