/**
 * Document : fp-onboarding - bottom-button.css
 * Created on : 9.2.2022, 17:47
 * @author : Jan Olšanský mladší (honza@olsansky.com)
 *
 * 1.0.0: 9.2.2022: JOml - vytvoření souboru
 **/

.bottom-button-container {
    height: 50px;
    max-width: 290px;
    flex: 2;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border: 3px solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-color: white;
}

.bottom-button-container-normal{
    border: 3px solid #00DEC4;
}

.bottom-button-container-inverse{
    background-color: #00DEC4;
    border: 3px solid #00DEC4;
}

.bottom-button-text {
    width: auto;
    height: auto;
    font-size: 24px;
    align-self: center;
}

.bottom-button-text-normal{
    color: #00DEC4;
}

.bottom-button-text-inverse{
    color: white;
}

.bottom-button-container:hover {
    /*filter: brightness(85%);*/
}

.bottom-button-text:hover,
.bottom-button-container:hover {
    cursor: pointer;
}

@media (max-width: 767px) {
    .bottom-button-text {
        font-size: 20px;
    }
}

@media (max-width: 479px) {
    .bottom-button-text {
        font-size: 17px;
    }
}